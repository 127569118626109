@import url(../../App-vars.css);

.welcome{
    position: relative;
    background-color: var(--primary-color);
    min-height: 100vh;
    padding-bottom: 50px;
    background-image: url(../../assets/svg/network.svg), url(../../assets/svg/bytes.svg), url(../../assets/svg/mboa-filigranne.svg);
    background-repeat: no-repeat;
    background-position:
    top 5px left 5px,
    top 5px right -5px,
    bottom 60px right -340px;
    background-size: 230px, 260px, 1100px;
}
.titled{
    font-weight: 400;
}
.bluely{
    color: var(--secondary-color) !important;
}
.bold{
    font-weight: 800 !important;
    color: var(--secondary-color);
}
.font-size-18{
    font-size: 18px;
}
.font-size-17{
    font-size: 17px;
}
.font-size-16{
    font-size: 16px;
}
.font-size-15{
    font-size: 15px;
}
.font-size-14{
    font-size: 14px;
}
.font-size-13{
    font-size: 13px;
}
.font-size-12{
    font-size: 12px;
}
.font-size-11{
    font-size: 11px;
}
.font-size-10{
    font-size: 10px;
}
.big-bold-white{
    font-weight: bold !important;
    color: var(--white-color);
    font-size: 3rem;
}
@media (max-width: 575.98px) {
    .welcome{
        min-height: 100vh;
        background-image: url(../../assets/svg/network.svg), url(../../assets/svg/mboa-filigranne.svg);
        background-position: top 5px right 5px, bottom 0px right -500px;
        background-size: 200px, 1000px;
    }
    
}
