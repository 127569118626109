
footer.footer{
    position: absolute;
    display: block;
    bottom: 0; 
    left: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #FFFFFF;
    box-shadow: 0 -3px 5px rgba(0,0,0,.05);
    box-sizing: border;
}
.logo2 img{
    position: relative;
    width: auto;
    height: 35px;
}
.website{
    z-index: 999;
    position: relative;
    display: block;
    float: right;
}
.website img{
    position: relative;
    margin-right: 10px;
    width: 20px;
    height: auto;
}
.website a{
    text-decoration: none;
    font-weight: lighter;
    color: #333333;
}