@import url(../../App-vars.css);
.ratebox{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 0 20px;
    background-color: #FFFFFF;
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow:  0 2px 3px rgba(0,0,0,0.1);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.ratebox .statement{
    margin: 0 0 20px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
}
.ratebox > ul.listrate{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
ul.listrate > li{
    display: inline-block;
    margin: 0 100px 0 0;
    padding: 0;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
ul.listrate > li > label.item-rate{
    display: block;
    margin: 0;
    padding: 0;
    width: 110px;
    height: 80px;
    text-decoration: none;
    text-align: center;
    color: #333333;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
label.item-rate > button{
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 38px;
    height: 38px;
    border:4px solid #B2B2B2;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 50%;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
label.item-rate > span{
    display: block;
    margin: 10px 0 0 0;
    padding: 0;
    width: 100%;
    text-align: center;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
label.item-rate.r-selected > button{
    background-color: #0C73BF;
    border:0;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
label.item-rate.r-selected > span{
    font-weight: bold;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.ratebox textarea{
    resize: none;
    outline: none;
}

.ratebox .description{
    position: relative;
    margin: 10px 0 20px 0;
    padding: 0;
    color: var(--dark-grey-color);
    font-size: 0.85rem;
}

@media (max-width: 575.98px) {
    .ratebox ul.listrate{
        display:flex;
        flex-direction: row !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    ul.listrate > li{
        display: inline-block;
        margin: 5px;
        padding: 0;
    }
    ul.listrate > li > label.item-rate{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
}
@media (max-width: 991.98px) {
    ul.listrate > li{
        display: inline-block;
        margin: 0 30px 0 0;
        padding: 0;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
}
