@import url(../../App-vars.css);

.selectaImg{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 20px 20px;
    background-color: #FFFFFF;
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selectaImg.lighter-bg{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 20px 20px;
    background-color: #FAFAFA !important;
    border: none;
    box-shadow: 0 3px 5px rgba(0,0,0,0) !important;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.selectaImg .btn-iselecta{
    /* position: relative; */
    display: inline-block;
    width: 95px;
    height: 95px;
    padding: 0;
    border: 0.5px solid #B2B2B2;
    box-sizing: border-box;
    background-color: #FFFFFF;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.selectaImg .box-other{
    display: block;
    background-color: #FAFAFA;
    margin: 20px 0;
    padding: 20px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selectaImg.lighter-bg .box-other{
    display: block;
    background-color: #FAFAFA;
    margin: 10px 0 0 0;
    padding: 10px 20px 0 0;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selectaImg .s-form{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 700px;
    border-radius: 5px;
    box-sizing: border-box;
}
.selectaImg.lighter-bg .s-form{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 700px;
    border-radius: 5px;
    box-sizing: border-box;
}
.selectaImg .other-value-i{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    background: #FFFFFF;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 150px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    text-align: center;
}
.selectaImg.lighter-bg .other-value{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    background: #FFFFFF;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 150px;
    height: 50px;
    line-height: 30px;
    box-sizing: border-box;
    text-align: center;
}
.selectaImg .selected{
    border: 5px solid var(--secondary-color) !important;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.selectaImg .statement{
    margin: 0 0 20px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inline-block{
    display: inline-block;
}

@media (max-width: 767.98px) {
    .selectaImg .btn-iselecta{
        /* display: block; */
        width: 95px;
        height: 95px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        border: 0.5px solid #B2B2B2;
        box-sizing: border-box;
        background-color: #FFFFFF;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .selectaImg .s-form{
        width: 100%;
    }
}

@media (max-width: 575.98px) {
    .selectaImg .s-form{
        width: 90%;
    }
    .selectaImg.lighter-bg .s-form{
        width: 100%;
    }
}

