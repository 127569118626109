@import url(../../App-vars.css);

.inputBtn {
    height: 46px;
    line-height: 46px;
    min-width: 160px;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: var(--secondary-color);
    color: #FFFFFF;
    border: none;
}

.inputBtn-text {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}

.inputBtn-img {
    height: 15px;
    width: 15px;
}
.inputBtn-img.right{
    margin-left: 5px;
}
.inputBtn-img.left{
    margin-right: 5px;
}

.inputBtn-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputBtn-sucess {
    background-color: rgb(40, 134, 82) !important;
}
.inputBtn-sucess:focus, .inputBtn-sucess:hover {
    background-color: rgba(40, 134, 82, 0.842) !important;
}

.inputBtn-default {
    background-color: var(--secondary-color) !important;
    color: #FFFFFF !important;
}
.inputBtn-default:focus, .inputBtn-default:hover {
    background-color: var(--primary-color) !important;
}

.inputBtn-error {
    background-color: rgb(191, 12, 12) !important;
    color: #FFFFFF !important;
}

.inputBtn-warning {
    background-color: rgb(243, 204, 77) !important;
    color: #333333 !important;
}

.inputBtn-primary {
    background-color: var(--secondary-color) !important;
    color: #FFFFFF !important;
}
.inputBtn-primary:focus, .inputBtn-primary:hover {
    background-color: rgb(9, 103, 170) !important;
}
.ico-on-hover:hover .inputBtn-img.right{
    animation: bounce-right-light 1s ease-in-out;
    -ms-animation: bounce-right-light 1s ease-in-out;
    -moz-animation: bounce-right-light 1s  ease-in-out;
    -o-animation: bounce-right-light 1s ease-in-out;
    -webkit-animation: bounce-right-light 1s ease-in-out;
    animation-iteration-count: infinite;
}


@media (max-width: 767.98px) {
    .inputBtn {
        display: block;
        height: 46px;
        line-height: 46px;
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .inputBtn:last-child {
        display: block;
        height: 46px;
        line-height: 46px;
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

