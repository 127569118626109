@import url(../../App-vars.css);
.InputComboBox{
    position: relative;
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 0 20px;
    background-color: #FFFFFF;
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
}
.InputComboBox .statement{
    margin: 0 0 5px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
}
.InputComboBox .txt-input{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: left;
    background-color: #FFFFFF;
    color: #333;
}
.dropdown-toggle::after {
    position: absolute;
    display: inline-block;
    right: 10px;
    top: 17.5px;
    /* vertical-align: .255em; */
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.d-width{
    width: 700px;
}
button,button:focus,button:visited{outline: none !important}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38,143,255,0);
}
.btn-primary.focus, .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(38,143,255,0);
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):hover{
    color: #333;
    background-color: transparent !important;
    border: thin solid rgba(112,112,112,0.3) !important;
}
a.dropdown-item:active{
    color: #333;
    background-color: transparent !important;
}
.InputComboBox .w-100{
    width: 100%;
}

@media (max-width: 767.98px) {
    .InputComboBox{
        padding: 15px 0 0 0;
    }
    .InputComboBox .dropdown-menu{
        font-size: 1rem;
    }
}