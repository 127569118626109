@import url(../../App-vars.css);
.actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.loader-box{
    z-index: 9999;
    position: fixed;
    display: flex;
    bottom: 10px;
    right: 10px;
    width: 300px;
    /* height: 100px; */
    background-color: var(--white-color);
    border: thin solid var(--secondary-color);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.loader-box .loader-spinner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
}

.loader-spinner span.l-spinner{
    width: 50px;
    height: 50px;
    border: 3px solid var(--secondary-color);
    border-left: 3px solid var(--white-color);
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.loader-box .loader-statement{
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 5px;
    color: var(--grey-color);
    font-weight: bold;
}
