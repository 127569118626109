.welcome .content.container .end{
    position: relative;
    background-image: url(../../../assets/svg/network-darker.svg);
    background-repeat: no-repeat;
    background-position: top 0px right 5px;
    background-size: 300px;
    min-height: 250px;
    background-color: transparent;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content-message{
    display: flex;
    position: relative;
    font-size: 1.5rem;
    font-weight: lighter;
    width: 610px;
}
span.ico-smiley{
    position: relative;
    display: block;
    margin: 0; padding: 0;
    width: 100px;
    height: 100px;
    background-image: url(../../../assets/svg/smiley-end.svg);
    background-repeat: no-repeat;
    background-position: center left 10px;
    background-size: 50px;
}
.content-message div{
    display: flex;
    padding: 0 0 0 25px;
    margin-bottom: 0 !important;
    align-items: center;
}
@media (max-width: 768px) {
    .content-message{
        flex-direction: column;
        font-size: 1.3rem;
        justify-content: center;
        align-items: center;
    }
    
}
@media (max-width: 575.98px) {
    /* .font-size-17{
        font-size: 14px;
    } */
    .big-bold-white{
        font-size: 25px;
    }
}
