@import url(../../App-vars.css);

.HeaderStep{
    position: relative;
    display: block;
    height: 175px;
    margin: 0; padding: 0;
    background-color: var(--primary-color);
    background-image: url(../../assets/svg/network.svg), url(../../assets/svg/bytes.svg);
    background-repeat: no-repeat;
    background-position:
    top 5px left 5px,
    top 0px right -15px;
    background-size: 210px, 260px;
    overflow: hidden;
    box-shadow: 0 2px 3px rgba(0,0,0,.16);
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.HeaderStep.offset{
    z-index: 999;
    position: fixed;
    display: block;
    top:0; left:0; right:0;
    height: auto;
    margin: 0; padding: 0;
    background-color: var(--primary-color);
    overflow: hidden;
    box-shadow: 0 2px 3px rgba(0,0,0,.16);
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.HeaderStep .main-title{
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: bold;
}
.HeaderStep.offset .main-title{
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
}
.HeaderStep .logo{
    position: relative;
    margin-top: 25px; 
    margin-bottom: 15px;
    padding: 0;
}
.HeaderStep .logo img{
    position: relative;
    width: auto;
    height: 30px;
}
.HeaderStep .title-page{
    display: flex;
    margin-top: 25px;
    align-items: baseline;
    justify-content: space-between;
}
.HeaderStep .title-page h3{
    position: relative;
    padding: 0;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 1.5em;
}

.HeaderStep .step-box{
    /* position: absolute; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 75px;
    margin: 0; padding: 0;
    background-color: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0,0,0,.16);
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


.HeaderStep .step-box .step-item{
    position: relative;
    display: inline-block;
    margin: 5px 0 0 0;
    height: inherit;
    text-align: center;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.HeaderStep .step-box .step-item button.btn-step{
    position: relative;
    display: block;
    margin: 5px auto 2px auto;
    width: 40px;
    height: 40px;
    border: 5px solid var(--grey-color);
    border-radius: 50%;
    box-sizing: border-box;
    background-color: var(--white-color);
    color: var(--grey-color);
    font-weight: bold;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.HeaderStep .step-box .step-item .step-label{
    position: relative;
    font-size: 0.8rem;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.HeaderStep .step-box .step-item.selected button.btn-step{
    border: 5px solid var(--secondary-color);
    box-sizing: border-box;
    color: var(--secondary-color);
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.HeaderStep .step-box .step-item.selected .step-label{
    font-weight: bold;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.HeaderStep .step-box .step-item.complete button.btn-step{
    border: 0;
    background-color: var(--secondary-color);
    color: var(--white-color);
    font-weight: bold;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.HeaderStep .step-box .step-item.complete .step-label{
    font-weight: normal;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 767.98px) {
    .HeaderStep{
        height: 110px;
        margin: 0; padding: 0;
        background-position:
        top 0px left 0px,
        top 0px right -5px;
        background-size: 105px, 130px;
    }
    .HeaderStep.offset .main-title{
        height: 50px;
        line-height: 50px;
    }
    .HeaderStep .main-title{
        height: 50px;
        line-height: 50px;
    }
    .HeaderStep .step-box{
        height: 60px;
    }
    .HeaderStep .step-box .step-item .step-label{
        display: none;
    }
    .HeaderStep .step-box .step-item.selected button.btn-step{
        border: 8px solid var(--secondary-color);
        box-sizing: border-box !important;
        width: 40px !important;
        height: 40px !important;
        border-radius: 50%  !important;
        -webkit-border-radius: 50%  !important;
        -moz-border-radius: 50%  !important;
        -ms-border-radius: 50%  !important;
        -o-border-radius: 50%  !important;
}
}