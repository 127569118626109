@keyframes rotate-niddle {
    from{
        transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
        -moz-transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -o-transform: rotateZ(0deg);
}
    to{
        transform: rotateZ(360deg);
        -webkit-transform: rotateZ(360deg);
        -moz-transform: rotateZ(360deg);
        -ms-transform: rotateZ(360deg);
        -o-transform: rotateZ(360deg);
}
}

#niddle{
    transform-origin: bottom;
    transform-box: fill-box;
    animation: rotate-niddle 10s steps(60, end) 0s infinite;
    -webkit-animation: rotate-niddle 10s steps(60, end) 0s infinite;
}

@media (max-width: 575.98px) {
    .chronometter{
        width: 100px;
        height: auto;
    }
}