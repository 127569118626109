@import url(../../App-vars.css);

.inputradio{
    position: relative;
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 0 20px;
    background-color: #FFFFFF;
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio .statement{
    margin: 0 0 10px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio .radio-content{
    position: relative
}
.inputradio .radio-item{
    display:flex;
    align-items: center;
    position: relative;
    margin-right: 50px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio .radio-item > button{
    position: relative;
    margin-right: 13px;
    width:35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid rgba(112,112,112,0.3);
    background-color: transparent;
    box-sizing: border-box !important;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.inputradio .radio-item > span{
    position: relative;
    display: inline-block;
    margin-right: 50px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio .selected{
    border: 10px solid var(--secondary-color) !important;
    box-sizing: border-box !important;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio .other{
    margin-top: 20px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio textarea{
    resize: none;
    outline: none;
}

@media (max-width: 767.98px) {
    .inputradio .radio-item{
        display:flex;
        margin-right: 0;
    }
    .inputradio .radio-item > button{
        margin-right: 15px;
        width: 35px !important;
        height: 35px !important;
        box-sizing: border-box !important;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
}
    .inputradio .radio-item > span{
        display: inline-block;
        margin-right: 40px;
        margin-bottom: 5px;
    }
}
