@import url(../../App-vars.css);

.header{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    margin: 0; padding: 0;
    background-color: transparent;
    width: 100%;
}
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo img{
    position: relative;
    width: auto;
    height: 30px;
}
.title-page{
    margin-top: 25px;
}
.logo h2{
    position: relative;
    padding: 0;
    color: var(--white-color);
    font-weight: bold;
    font-size: 1.5em;
    text-transform: capitalize;
}
.main-title{
    margin: 0;
    padding: 0;
    color: var(--white-color);
    font-weight: 300;
    font-size: 1.5em;
}
@media (max-width: 575.98px) {
    .header{
        height: 100px;
    }
    .title-page h3{
        font-weight: 500;
        font-size: 1.3em;
    }  
}