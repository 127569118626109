@import url(../../../App-vars.css);

.welcome{
    color: var(--white-color);
}
.welcome .content.container{
    position: relative;
}
.w-pmessage{
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 1rem;
    font-weight: 500;
}
.w-pmessage > p {
    margin: 4px;
}
span.ico-tech{
    position: relative;
    display: block;
    margin: 0 20px 0 0; padding: 0;
    width: 200px;
    height: 200px;
}
.time-defined{
    font-size: 2.5rem;
}

@media (max-width: 575.98px) {
    
    .w-pmessage{
        display: flex;
        flex-direction: column;
        position: relative;
        font-size: 1.1rem;
        align-items: center;
    }
    span.ico-tech{
        position: relative;
        display: block;
        margin: 0; padding: 0;
        width: 180px;
        height: 180px;
        /* background-image: url(../../../assets/svg/digitalComputer.svg);
        background-repeat: no-repeat;
        background-position: top;
        background-size: 180px; */
    }
    .time-defined{
        font-size: 2rem;
    }
    .timer-msg + p {
        font-size: 0.9rem;
    }
    .w-600{
        width: inherit;
    }
}

@media (max-width: 768px) {
    
    .w-pmessage{
        display: flex;
        flex-direction: column;
        position: relative;
        font-size: 1rem;
        align-items: center;
    }
    .ico-on-hover{
        margin-top: 50px;
    }
    .timer-msg{
        font-size: 1.1rem;
    }
    .timer-msg .bold{
        font-size: 1.3rem;
    }
}
