@import url(../../../App-vars.css);

.selectaImageItem{
    position: relative;
    display: inline-block;
}
.selectaImageItem button{
    position: relative;
    width: 95px;
    height: 95px;
    margin-right: 20px;
    padding: 0;
    border: none;
    box-sizing: border-box;
    background-color: #FFFFFF;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selectaImageItem button::after{
    position: absolute;
    top:0; left: 0; bottom: 0; right: 0;
    content:" ";
    border: 0.5px solid #B2B2B2;
    box-sizing: border-box;
    background-color: transparent;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.si-selected{
    position: relative;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.si-selected::after{
    position: absolute;
    top:0; left: 0; bottom: 0; right: 0;
    content:" ";
    border: 5px solid var(--secondary-color) !important;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 767.98px) {
    .selectaImageItem button{
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .selectaImageItem{
        /* display: flex;
        flex-wrap: wrap;
        margin: 5px auto !important;
        justify-content: center;*/
    } 
}
