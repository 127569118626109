@import url(https://fonts.googleapis.com/css?family=Exo:200,300,400,500,700&display=swap);
html,body {
  margin: 0;
  font-family:  "Exo",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  font-size: 0.9em;
  -ms-scroll-behavior: smooth;
  -moz-scroll-behavior: smooth;
  -o-scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input, button, select{
  outline: none !important;
}

:root{
    --primary-color: #335284;
    --secondary-color: #0C73BF;
    --grey-color: #B2B2B2;
    --light-grey-color: #FAFAFA;
    --dark-grey-color: #333333;
    --white-color: rgb(255, 255, 255);
    --light-white-color: rgba(255, 255, 255, 0.57);
    --default-border-width: 5px;
}
.App {
  position: relative;
  font-family:  "Exo","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
  margin: 0; padding: 0;
  background-color: var(--light-grey-color);
  min-height: 100vh;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.mt-10{
  margin-top: 10px;
}
.mt-15{
  margin-top: 15px !important;
}
.mt-20{
  margin-top: 20px;
}
.mt-30{
  margin-top: 30px;
}
.mt-40{
  margin-top: 40px;
}
.mt-50{
  margin-top: 50px;
}
.mt-100{
  margin-top: 100px;
}
.mb-100{
  margin-bottom: 100px;
}
.ml-20{
  margin-left: 20px;
}
.w-550{
  width: 550px;
}
.w-600{
  width: 600px;
}
.mb-20{
  margin-bottom: 20px;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.p-start{opacity: 0}
.actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.container{
  padding: 0 75px !important;
}
.pt-100{
  padding-top: 100px !important;
}
.pb-50{
  padding-bottom: 50px !important;
}
.none{
  display: none
}
.block{
  display: block
}
.light-white-color {
  color: var(--light-white-color);
}
input[type="number"],
input[type="text"],
select:focus,
textarea {
  font-size: 16px;
}
@media (max-width: 767.98px) {
  .container{
    padding: 0 15px !important;
    box-sizing: border-box;
  }
  .actions-wrapper {
    flex-direction: column;
    justify-content: center;
  }
}


/* Animations */

@-webkit-keyframes bounce-down{
    0%{
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    50%{
        -webkit-transform: translateY(-75px);
                transform: translateY(-75px);
    }
    100%{
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
  }

@keyframes bounce-down{
    0%{
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    50%{
        -webkit-transform: translateY(-75px);
                transform: translateY(-75px);
    }
    100%{
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
  }

@-webkit-keyframes fade-in-out{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes fade-in-out{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-out-light{
  from{
    opacity: 0.2;
  }
  to{
    opacity: 0.8;
  }
}
@keyframes fade-in-out-light{
  from{
    opacity: 0.2;
  }
  to{
    opacity: 0.8;
  }
}
@-webkit-keyframes fade-in-top-lighter{
    from{
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
    opacity: 0;
    }
    to{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0.6;
    }
  }
@keyframes fade-in-top-lighter{
    from{
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
    opacity: 0;
    }
    to{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0.6;
    }
  }

  @-webkit-keyframes fade-in-top-light{
    from{
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
    opacity: 0;
    }
    to{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0.8;
    }
  }

  @keyframes fade-in-top-light{
    from{
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
    opacity: 0;
    }
    to{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0.8;
    }
  }
  @-webkit-keyframes fade-in-top{
    from{
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
    opacity: 0;
    }
    to{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    }
  }
  @keyframes fade-in-top{
    from{
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
    opacity: 0;
    }
    to{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    }
  }
  @-webkit-keyframes fade-in-bottom{
    from{
    transform: translateY(75px);
    opacity: 0;
    -webkit-transform: translateY(75px);
    -moz-transform: translateY(75px);
    -ms-transform: translateY(75px);
    -o-transform: translateY(75px);
}
    to{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    }
  }
  @keyframes fade-in-bottom{
    from{
    transform: translateY(75px);
    opacity: 0;
    -webkit-transform: translateY(75px);
    -moz-transform: translateY(75px);
    -ms-transform: translateY(75px);
    -o-transform: translateY(75px);
}
    to{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    }
  }
  @-webkit-keyframes fade-in-left-light{
    from{
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
    }
    to{
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    }
  }
  @keyframes fade-in-left-light{
    from{
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
    }
    to{
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    }
  }
  @-webkit-keyframes fade-in-right-light{
    from{
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
    }
    to{
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    }
  }
  @keyframes fade-in-right-light{
    from{
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
    }
    to{
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    }
  }
  @-webkit-keyframes fade-out-top-fade{
    0%{
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 0.7;
    }
    100%{
        -webkit-transform: translateY(-300px);
                transform: translateY(-300px);
        opacity: 0;
    }
  }
  @keyframes fade-out-top-fade{
    0%{
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 0.7;
    }
    100%{
        -webkit-transform: translateY(-300px);
                transform: translateY(-300px);
        opacity: 0;
    }
  }
  
  @-webkit-keyframes o-fadeInLeft{
    0%{
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      -webkit-transform: translateX(-100px) translateY(-50px);
              transform: translateX(-100px) translateY(-50px);
      opacity: 0;
    }
    100%{
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      -webkit-transform: translateX(-50px) translateY(-50px);
              transform: translateX(-50px) translateY(-50px);
      opacity: 1;
    }
  }
  
  @keyframes o-fadeInLeft{
    0%{
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      -webkit-transform: translateX(-100px) translateY(-50px);
              transform: translateX(-100px) translateY(-50px);
      opacity: 0;
    }
    100%{
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      -webkit-transform: translateX(-50px) translateY(-50px);
              transform: translateX(-50px) translateY(-50px);
      opacity: 1;
    }
  }
  @-webkit-keyframes o-fadeInRight{
    0%{
      width: 70%;
      -webkit-transform: scale(0.8) translateX(-20px);
              transform: scale(0.8) translateX(-20px);
      opacity: 0;
    }
    100%{
      width: 70%;
      -webkit-transform: scale(0.8) translateX(-70px);
              transform: scale(0.8) translateX(-70px);
      opacity: 1;
    }
  }
  @keyframes o-fadeInRight{
    0%{
      width: 70%;
      -webkit-transform: scale(0.8) translateX(-20px);
              transform: scale(0.8) translateX(-20px);
      opacity: 0;
    }
    100%{
      width: 70%;
      -webkit-transform: scale(0.8) translateX(-70px);
              transform: scale(0.8) translateX(-70px);
      opacity: 1;
    }
  }
  @-webkit-keyframes o-fadeInLeft-up{
    0%{
      
      opacity: 0;
      display: inline-block; 
      margin-left: auto !important;
      margin-right: auto !important;/**/
      margin-bottom: 15px;
      width: auto;
      float: none !important;
      -webkit-transform: scale(1) translateX(-50px);
              transform: scale(1) translateX(-50px);
    }
    100%{
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
      opacity: 1;
      display: inline-block; 
      margin-left: auto !important;
      margin-right: auto !important;/**/
      margin-bottom: 15px;
      width: auto;
      float: none !important;
    }
  }
  @keyframes o-fadeInLeft-up{
    0%{
      
      opacity: 0;
      display: inline-block; 
      margin-left: auto !important;
      margin-right: auto !important;/**/
      margin-bottom: 15px;
      width: auto;
      float: none !important;
      -webkit-transform: scale(1) translateX(-50px);
              transform: scale(1) translateX(-50px);
    }
    100%{
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
      opacity: 1;
      display: inline-block; 
      margin-left: auto !important;
      margin-right: auto !important;/**/
      margin-bottom: 15px;
      width: auto;
      float: none !important;
    }
  }
  @-webkit-keyframes o-fadeInRight-up{
    0%{
      -webkit-transform: scale(0.9) translateX(50px);
              transform: scale(0.9) translateX(50px);
      opacity: 0;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    100%{
      -webkit-transform: scale(0.9) translateX(0);
              transform: scale(0.9) translateX(0);
      opacity: 1;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @keyframes o-fadeInRight-up{
    0%{
      -webkit-transform: scale(0.9) translateX(50px);
              transform: scale(0.9) translateX(50px);
      opacity: 0;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    100%{
      -webkit-transform: scale(0.9) translateX(0);
              transform: scale(0.9) translateX(0);
      opacity: 1;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @-webkit-keyframes slide-top{
    from{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
    to{
    -webkit-transform: translateY(-650px);
            transform: translateY(-650px);
    opacity: 1;
    }
  }
  @keyframes slide-top{
    from{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
    to{
    -webkit-transform: translateY(-650px);
            transform: translateY(-650px);
    opacity: 1;
    }
  }
  @-webkit-keyframes slide-in-top{
    from{
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
    to{
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes slide-in-top{
    from{
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
    to{
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @-webkit-keyframes slide-in-top-light{
    from{
      -webkit-transform: translateY(-15px);
              transform: translateY(-15px);
    }
    to{
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes slide-in-top-light{
    from{
      -webkit-transform: translateY(-15px);
              transform: translateY(-15px);
    }
    to{
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @-webkit-keyframes slide-left-map{
    from{
    -webkit-transform: translateX(0);
            transform: translateX(0);
    }
    to{
    -webkit-transform: translateX(-660px);
            transform: translateX(-660px);
    opacity: 1;
    }
  }
  @keyframes slide-left-map{
    from{
    -webkit-transform: translateX(0);
            transform: translateX(0);
    }
    to{
    -webkit-transform: translateX(-660px);
            transform: translateX(-660px);
    opacity: 1;
    }
  }
  /* slide-top-start-fade */
  @-webkit-keyframes slide-top-start-fade{
    0%{
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
      opacity: 0;
    }
  }
  @keyframes slide-top-start-fade{
    0%{
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
      opacity: 0;
    }
  }
  @-webkit-keyframes slide-left-right-opacity{
    0%{
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 0;
    }
    33.33%{
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 1;
    }
    66.66%{
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
    }
    100%{
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 0;
    }
  }
  @keyframes slide-left-right-opacity{
    0%{
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 0;
    }
    33.33%{
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 1;
    }
    66.66%{
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
    }
    100%{
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 0;
    }
  }
  @-webkit-keyframes bounce-right-light{
    0%{
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    50%{
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }
    100%{
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes bounce-right-light{
    0%{
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    50%{
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
    }
    100%{
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes spinner{
    from {
      transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
      -moz-transform: rotateZ(0deg);
      -ms-transform: rotateZ(0deg);
      -o-transform: rotateZ(0deg);
}
    to {
      transform: rotateZ(360deg);
      -webkit-transform: rotateZ(360deg);
      -moz-transform: rotateZ(360deg);
      -ms-transform: rotateZ(360deg);
      -o-transform: rotateZ(360deg);
}
  }

  @keyframes spinner{
    from {
      transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
      -moz-transform: rotateZ(0deg);
      -ms-transform: rotateZ(0deg);
      -o-transform: rotateZ(0deg);
}
    to {
      transform: rotateZ(360deg);
      -webkit-transform: rotateZ(360deg);
      -moz-transform: rotateZ(360deg);
      -ms-transform: rotateZ(360deg);
      -o-transform: rotateZ(360deg);
}
  }

  /* Animated classes */

  /* delay classes animations 0s - 10s with half between */
  .delay-less-1{
    -webkit-animation-delay: 0.1s !important;
            animation-delay: 0.1s !important;
  }
  .delay-less-2{
    -webkit-animation-delay: 0.2s !important;
            animation-delay: 0.2s !important;
  }
  .delay-less-3{
    -webkit-animation-delay: 0.3s !important;
            animation-delay: 0.3s !important;
  }
  .delay-less-4{
    -webkit-animation-delay: 0.4s !important;
            animation-delay: 0.4s !important;
  }
  .delay-less-6{
    -webkit-animation-delay: 0.6s !important;
            animation-delay: 0.6s !important;
  }
  .delay-half{
    -webkit-animation-delay: 0.5s !important;
            animation-delay: 0.5s !important;
  }
  .delay-0{
    -webkit-animation-delay: 0s !important;
            animation-delay: 0s !important;
  }
  .delay-1{
    -webkit-animation-delay: 1s !important;
            animation-delay: 1s !important;
  }
  .delay-1-half{
    -webkit-animation-delay: 1.5s !important;
            animation-delay: 1.5s !important;
  }
  .delay-2{
    -webkit-animation-delay: 2s !important;
            animation-delay: 2s !important;
  }
  .delay-2-half{
    -webkit-animation-delay: 2.5s !important;
            animation-delay: 2.5s !important;
  }
  .delay-3{
    -webkit-animation-delay: 3s !important;
            animation-delay: 3s !important;
  }
  .delay-3-half{
    -webkit-animation-delay: 3.5s !important;
            animation-delay: 3.5s !important;
  }
  .delay-4{
    -webkit-animation-delay: 4s !important;
            animation-delay: 4s !important;
  }
  .delay-4-half{
    -webkit-animation-delay: 4.5s !important;
            animation-delay: 4.5s !important;
  }
  .delay-5{
    -webkit-animation-delay: 5s !important;
            animation-delay: 5s !important;
  }
  .delay-5-half{
    -webkit-animation-delay: 5.5s !important;
            animation-delay: 5.5s !important;
  }
  .delay-6{
    -webkit-animation-delay: 6s !important;
            animation-delay: 6s !important;
  }
  .delay-6-half{
    -webkit-animation-delay: 6.5s !important;
            animation-delay: 6.5s !important;
  }
  .delay-7{
    -webkit-animation-delay: 7s !important;
            animation-delay: 7s !important;
  }
  .delay-7-half{
    -webkit-animation-delay: 7.5s !important;
            animation-delay: 7.5s !important;
  }
  .delay-8{
    -webkit-animation-delay: 8s !important;
            animation-delay: 8s !important;
  }
  .delay-8-halh{
    -webkit-animation-delay: 8.5s !important;
            animation-delay: 8.5s !important;
  }
  .delay-9{
    -webkit-animation-delay: 9s !important;
            animation-delay: 9s !important;
  }
  .delay-9-half{
    -webkit-animation-delay: 9.5s !important;
            animation-delay: 9.5s !important;
  }
  .delay-10{
    -webkit-animation-delay: 10s !important;
            animation-delay: 10s !important;
  }
  /* duration classes animations 1s - 10s */
  .duration-1{
    -webkit-animation-duration: 1s !important;
            animation-duration: 1s !important;
  }
  .duration-2{
    -webkit-animation-duration: 2s !important;
            animation-duration: 2s !important;
  }
  .duration-3{
    -webkit-animation-duration: 3s !important;
            animation-duration: 3s !important;
  }
  .duration-4{
    -webkit-animation-duration: 4s !important;
            animation-duration: 4s !important;
  }
  .duration-5{
    -webkit-animation-duration: 5s !important;
            animation-duration: 5s !important;
  }
  .duration-6{
    -webkit-animation-duration: 6s !important;
            animation-duration: 6s !important;
  }
  .duration-7{
    -webkit-animation-duration: 7s !important;
            animation-duration: 7s !important;
  }
  .duration-8{
    -webkit-animation-duration: 8s !important;
            animation-duration: 8s !important;
  }
  .duration-9{
    -webkit-animation-duration: 9s !important;
            animation-duration: 9s !important;
  }
  .duration-10{
    -webkit-animation-duration: 10s !important;
            animation-duration: 10s !important;
  }
  .duration-half{
    -webkit-animation-duration: 0.5s !important;
            animation-duration: 0.5s !important;
  }
  .duration-1-half{
    -webkit-animation-duration: 1.5s !important;
            animation-duration: 1.5s !important;
  }
  .duration-2-half{
    -webkit-animation-duration: 2.5s !important;
            animation-duration: 2.5s !important;
  }
  .duration-3-half{
    -webkit-animation-duration: 3.5s !important;
            animation-duration: 3.5s !important;
  }
  .duration-4-half{
    -webkit-animation-duration: 4.5s !important;
            animation-duration: 4.5s !important;
  }
  .duration-5-half{
    -webkit-animation-duration: 5.5s !important;
            animation-duration: 5.5s !important;
  }
  .duration-6-half{
    -webkit-animation-duration: 6.5s !important;
            animation-duration: 6.5s !important;
  }
  .duration-7-half{
    -webkit-animation-duration: 7.5s !important;
            animation-duration: 7.5s !important;
  }
  .duration-8-half{
    -webkit-animation-duration: 8.5s !important;
            animation-duration: 8.5s !important;
  }
  .duration-9-half{
    -webkit-animation-duration: 9.5s !important;
            animation-duration: 9.5s !important;
  }
  .duration-10-half{
    -webkit-animation-duration: 10.5s !important;
            animation-duration: 10.5s !important;
  }
  /* play state animation (paused - running)*/
  .paused{-webkit-animation-play-state: paused !important;animation-play-state: paused !important;}
  .running{-webkit-animation-play-state: running !important;animation-play-state: running !important;}
  /* infinite animation */
  .infinite{-webkit-animation-iteration-count: infinite !important;animation-iteration-count: infinite !important;}
  /* alternate animation */
  .alternate{-webkit-animation-direction: alternate !important;animation-direction: alternate !important;}
  /* reverse animation */
  .reverse{animation-direction: reverse !important;}
  /* alternate-reverse animation */
  .alternate-reverse{animation-direction: alternate-reverse !important;}
  /* animation fill mode --> indique la façon dont une animation CSS doit appliquer les styles à sa cible avant et après son exécution.*/
  .animation-fill-mode-both{-webkit-animation-fill-mode: both !important;animation-fill-mode: both !important;}
  .animation-fill-mode-none{-webkit-animation-fill-mode: none !important;animation-fill-mode: none !important;}
  .animation-fill-mode-forwards{-webkit-animation-fill-mode: forwards !important;animation-fill-mode: forwards !important;}
  .animation-fill-mode-backwards{-webkit-animation-fill-mode: backwards !important;animation-fill-mode: backwards !important;}

  /* bounce up */
  .bounce-up{
    animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -ms-animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -moz-animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -o-animation: bounce-down 3s ease-in-out 1s infinite alternate;
    -webkit-animation: bounce-down 3s ease-in-out 1s infinite alternate;
  }
  /* fades effects */
  .fade-in-out-light{
    animation: fade-in-out-light 2s ease-in-out;
    -ms-animation: fade-in-out-light 2s ease-in-out;
    -moz-animation: fade-in-out-light 2s ease-in-out;
    -o-animation: fade-in-out-light 2s ease-in-out;
    -webkit-animation: fade-in-out-light 2s ease-in-out;
  }
  .fade-in-out{
    animation: fade-in-out 2s ease-in-out;
    -ms-animation: fade-in-out 2s ease-in-out;
    -moz-animation: fade-in-out 2s ease-in-out;
    -o-animation: fade-in-out 2s ease-in-out;
    -webkit-animation: fade-in-out 2s ease-in-out;
  }
  .fade-in-top{
    animation: fade-in-top 0.5s ease-in-out;
    -ms-animation: fade-in-top 0.5s ease-in-out;
    -moz-animation: fade-in-top 0.5s ease-in-out;
    -o-animation: fade-in-top 0.5s ease-in-out;
    -webkit-animation: fade-in-top 0.5s ease-in-out;
  }
  .fade-in-bottom{
    animation: fade-in-bottom 0.5s ease-in-out;
    -ms-animation: fade-in-top 0.5s ease-in-out;
    -moz-animation: fade-in-top 0.5s ease-in-out;
    -o-animation: fade-in-top 0.5s ease-in-out;
    -webkit-animation: fade-in-bottom 0.5s ease-in-out;
}
  .fade-in-top-light{
    animation: fade-in-top-light 0.5s ease-in-out;
    -ms-animation: fade-in-top-light 0.5s ease-in-out;
    -moz-animation: fade-in-top-light 0.5s ease-in-out;
    -o-animation: fade-in-top-light 0.5s ease-in-out;
    -webkit-animation: fade-in-top-light 0.5s ease-in-out;
  }
  .fade-in-top-lighter{
    animation: fade-in-top-lighter 0.5s ease-in-out;
    -ms-animation: fade-in-top-lighter 0.5s ease-in-out;
    -moz-animation: fade-in-top-lighter 0.5s ease-in-out;
    -o-animation: fade-in-top-lighter 0.5s ease-in-out;
    -webkit-animation: fade-in-top-lighter 0.5s ease-in-out;
  }
  .o-fadeInLeft{
    animation: o-fadeInLeft 2s ease-in-out;
    -ms-animation: o-fadeInLeft 2s ease-in-out;
    -moz-animation: o-fadeInLeft 2s ease-in-out;
    -o-animation: o-fadeInLeft 2s ease-in-out;
    -webkit-animation: o-fadeInLeft 2s ease-in-out;
  }
  
  .o-fadeInLeft-up{
    animation: o-fadeInLeft-up 2s ease-in-out;
    -ms-animation: o-fadeInLeft-up 2s ease-in-out;
    -moz-animation: o-fadeInLeft-up 2s ease-in-out;
    -o-animation: o-fadeInLeft-up 2s ease-in-out;
    -webkit-animation: o-fadeInLeft-up 2s ease-in-out;
  }
  .o-fadeInRight{
    animation: o-fadeInRight 2s ease-in-out;
    -ms-animation: o-fadeInRight 2s ease-in-out;
    -moz-animation: o-fadeInRight 2s ease-in-out;
    -o-animation: o-fadeInRight 2s ease-in-out;
    -webkit-animation: o-fadeInRight 2s ease-in-out;
  }
  
  .o-fadeInRight-up{
    animation: o-fadeInRight-up 2s ease-in-out;
    -ms-animation: o-fadeInRight-up 2s ease-in-out;
    -moz-animation: o-fadeInRight-up 2s ease-in-out;
    -o-animation: o-fadeInRight-up 2s ease-in-out;
    -webkit-animation: o-fadeInRight-up 2s ease-in-out;
  }
  /* fade-out-top-fade */
  .fade-out-top-fade{
    animation: fade-out-top-fade 2s ease-in-out;
    -ms-animation: fade-out-top-fade 2s ease-in-out;
    -moz-animation: fade-out-top-fade 2s ease-in-out;
    -o-animation: fade-out-top-fade 2s ease-in-out;
    -webkit-animation: fade-out-top-fade 2s ease-in-out;
  }
  .fade-in-left-light{
    animation: fade-in-left-light 2s ease-in-out;
    -ms-animation: fade-in-left-light 2s ease-in-out;
    -moz-animation: fade-in-left-light 2s ease-in-out;
    -o-animation: fade-in-left-light 2s ease-in-out;
    -webkit-animation: fade-in-left-light 2s ease-in-out;
  }
  .fade-in-right-light{
    animation: fade-in-right-light 2s ease-in-out;
    -ms-animation: fade-in-right-light 2s ease-in-out;
    -moz-animation: fade-in-right-light 2s ease-in-out;
    -o-animation: fade-in-right-light 2s ease-in-out;
    -webkit-animation: fade-in-right-light 2s ease-in-out;
  }
  /* slides effects */
  .slide-top{
    animation: slide-top 3s ease-in-out;
    -ms-animation: slide-top 3s ease-in-out;
    -moz-animation: slide-top 3s ease-in-out;
    -o-animation: slide-top 3s ease-in-out;
    -webkit-animation: slide-top 3s ease-in-out;
  }
  .slide-top-start-fade{
    animation: slide-top-start-fade 2s linear;
    -ms-animation: slide-top-start-fade 2s linear;
    -moz-animation: slide-top-start-fade 2s linear;
    -o-animation: slide-top-start-fade 2s linear;
    -webkit-animation: slide-top-start-fade 2s linear;
  }
  .slide-left-map{
    animation: slide-left-map 2s linear;
    -ms-animation: slide-left-map 2s linear;
    -moz-animation: slide-left-map 2s linear;
    -o-animation: slide-left-map 2s linear;
    -webkit-animation: slide-left-map 2s linear;
  }
  .slide-left-right-opacity{
    animation: slide-left-right-opacity 2s ease-in-out;
    -ms-animation: slide-left-right-opacity 2s ease-in-out;
    -moz-animation: slide-left-right-opacity 2s ease-in-out;
    -o-animation: slide-left-right-opacity 2s ease-in-out;
    -webkit-animation: slide-left-right-opacity 2s ease-in-out;
  }
  .slide-in-top-light{
    animation: slide-in-top-light 2s ease-in-out;
    -ms-animation: slide-in-top-light 2s ease-in-out;
    -moz-animation: slide-in-top-light 2s ease-in-out;
    -o-animation: slide-in-top-light 2s ease-in-out;
    -webkit-animation: slide-in-top-light 2s ease-in-out;
  }
  .slide-in-top{
    animation: slide-in-top 2s ease-in-out;
    -ms-animation: slide-in-top 2s ease-in-out;
    -moz-animation: slide-in-top 2s ease-in-out;
    -o-animation: slide-in-top 2s ease-in-out;
    -webkit-animation: slide-in-top 2s ease-in-out;
  }
  .spinner{
    animation: spinner 1s ease-in-out;
    -ms-animation: slide-in-top 2s ease-in-out;
    -moz-animation: slide-in-top 2s ease-in-out;
    -o-animation: slide-in-top 2s ease-in-out;
    -webkit-animation: spinner 1s ease-in-out;
}

.actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.loader-box{
    z-index: 9999;
    position: fixed;
    display: flex;
    bottom: 10px;
    right: 10px;
    width: 300px;
    /* height: 100px; */
    background-color: var(--white-color);
    border: thin solid var(--secondary-color);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.loader-box .loader-spinner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
}

.loader-spinner span.l-spinner{
    width: 50px;
    height: 50px;
    border: 3px solid var(--secondary-color);
    border-left: 3px solid var(--white-color);
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.loader-box .loader-statement{
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 5px;
    color: var(--grey-color);
    font-weight: bold;
}


.inputBtn {
    height: 46px;
    line-height: 46px;
    min-width: 160px;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: var(--secondary-color);
    color: #FFFFFF;
    border: none;
}

.inputBtn-text {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}

.inputBtn-img {
    height: 15px;
    width: 15px;
}
.inputBtn-img.right{
    margin-left: 5px;
}
.inputBtn-img.left{
    margin-right: 5px;
}

.inputBtn-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputBtn-sucess {
    background-color: rgb(40, 134, 82) !important;
}
.inputBtn-sucess:focus, .inputBtn-sucess:hover {
    background-color: rgba(40, 134, 82, 0.842) !important;
}

.inputBtn-default {
    background-color: var(--secondary-color) !important;
    color: #FFFFFF !important;
}
.inputBtn-default:focus, .inputBtn-default:hover {
    background-color: var(--primary-color) !important;
}

.inputBtn-error {
    background-color: rgb(191, 12, 12) !important;
    color: #FFFFFF !important;
}

.inputBtn-warning {
    background-color: rgb(243, 204, 77) !important;
    color: #333333 !important;
}

.inputBtn-primary {
    background-color: var(--secondary-color) !important;
    color: #FFFFFF !important;
}
.inputBtn-primary:focus, .inputBtn-primary:hover {
    background-color: rgb(9, 103, 170) !important;
}
.ico-on-hover:hover .inputBtn-img.right{
    animation: bounce-right-light 1s ease-in-out;
    -ms-animation: bounce-right-light 1s ease-in-out;
    -moz-animation: bounce-right-light 1s  ease-in-out;
    -o-animation: bounce-right-light 1s ease-in-out;
    -webkit-animation: bounce-right-light 1s ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}


@media (max-width: 767.98px) {
    .inputBtn {
        display: block;
        height: 46px;
        line-height: 46px;
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .inputBtn:last-child {
        display: block;
        height: 46px;
        line-height: 46px;
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}


.inputbox{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 0 0 5px;
    background-color: #FFFFFF;
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
}
.inputbox.lighter-bg{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 0 20px;
    background-color: #FAFAFA !important;
    border: none;
    box-shadow: 0 3px 5px rgba(0,0,0,0) !important;
}
.inputbox .statement{
    margin: 0 0 5px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
}
.inputbox .txt-input{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
}
.inputradio{
    position: relative;
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 0 20px;
    background-color: #FFFFFF;
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio .statement{
    margin: 0 0 10px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio .radio-content{
    position: relative
}
.inputradio .radio-item{
    display:flex;
    align-items: center;
    position: relative;
    margin-right: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio .radio-item > button{
    position: relative;
    margin-right: 13px;
    width:35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid rgba(112,112,112,0.3);
    background-color: transparent;
    box-sizing: border-box !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.inputradio .radio-item > span{
    position: relative;
    display: inline-block;
    margin-right: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio .selected{
    border: 10px solid var(--secondary-color) !important;
    box-sizing: border-box !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio .other{
    margin-top: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inputradio textarea{
    resize: none;
    outline: none;
}

@media (max-width: 767.98px) {
    .inputradio .radio-item{
        display:flex;
        margin-right: 0;
    }
    .inputradio .radio-item > button{
        margin-right: 15px;
        width: 35px !important;
        height: 35px !important;
        box-sizing: border-box !important;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
}
    .inputradio .radio-item > span{
        display: inline-block;
        margin-right: 40px;
        margin-bottom: 5px;
    }
}

.selecta{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 20px 20px;
    background-color: var(--white-color);
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta.lighter-bg{
    display: block;
    margin: 0 0 20px 0;
    padding: 20px 50px;
    background-color: var(--light-grey-color) !important;
    border: none;
    box-shadow: 0 3px 5px rgba(0,0,0,0) !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn-selecta{
    position: relative;
    display: inline-block;
    height: 50px;
    margin-right: 20px;
    padding: 0 20px;
    border: 0.5px solid var(--grey-color);
    box-sizing: border-box;
    background-color: var(--white-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta .btn-selecta.img{
    position: relative;
    display: inline-block;
    height: 95px;
    line-height: 95px;
    width: 95px;
    padding: 0;
    text-align: center;
    border: 0.5px solid var(--grey-color);
    box-sizing: border-box;
    background-color: var(--white-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta .box-other{
    display: block;
    background-color: var(--light-grey-color);
    margin: 20px 0;
    padding: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta.lighter-bg .box-other{
    display: block;
    background-color: var(--light-grey-color);
    margin: 20px 0 0 0;
    padding: 20px 20px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta .s-form{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 50px;
    width: 700px;
    border-radius: 5px;
    box-sizing: border-box;
}
.selecta.lighter-bg .s-form{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 700px;
    border-radius: 5px;
    box-sizing: border-box;
}
.other-value{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    background: var(--white-color);
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 150px;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    text-align: center;
}
.lighter-bg .other-value{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    background: var(--white-color);
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 150px;
    height: 50px;
    line-height: 30px;
    box-sizing: border-box;
    text-align: center;
}
.selecta .selected{
    border: 5px solid var(--secondary-color) !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta .statement{
    margin: 0 0 10px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
}
.inline-block{
    display: inline-block;
}
.selecta textarea {
    resize: none;
}
.selectaItem{
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}
.selectaItem button{
    position: relative;
    min-height: 40px;
    margin-right: 10px;
    padding: 0 10px;
    border: 0.5px solid #B2B2B2;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.selectaItem .s-selected{
    border: 5px solid var(--secondary-color) !important;
    padding: 0 5.5px !important;
    box-sizing: border-box !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
div.popover{
    /* background-color: #CBE9FF;
     border-radius: 0; */
    font-family: "Exo", Arial, Helvetica, sans-serif
}
.arrow::after,.arrow::before{
    border-color: #CBE9FF; 
}

@media (max-width: 575.98px) {
    .selectaItem{
        position: relative;
        display: block;
    }
    .selectaItem button{
        position: relative;
        margin: 0 0 5px 0;
        padding: 0 10px;
        border: 0.5px solid #B2B2B2;
        width: 100%;
        border-radius: 5px;
        font-size: 0.9rem;
        box-sizing: border-box;
        background-color: #FFFFFF;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
}

@media (max-width: 1024px) {
    .selectaItem{
        margin-bottom: 10px;
    }
}

.InputComboBox{
    position: relative;
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 0 20px;
    background-color: #FFFFFF;
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
}
.InputComboBox .statement{
    margin: 0 0 5px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
}
.InputComboBox .txt-input{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: left;
    background-color: #FFFFFF;
    color: #333;
}
.dropdown-toggle::after {
    position: absolute;
    display: inline-block;
    right: 10px;
    top: 17.5px;
    /* vertical-align: .255em; */
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.d-width{
    width: 700px;
}
button,button:focus,button:visited{outline: none !important}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38,143,255,0);
}
.btn-primary.focus, .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(38,143,255,0);
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):hover{
    color: #333;
    background-color: transparent !important;
    border: thin solid rgba(112,112,112,0.3) !important;
}
a.dropdown-item:active{
    color: #333;
    background-color: transparent !important;
}
.InputComboBox .w-100{
    width: 100%;
}

@media (max-width: 767.98px) {
    .InputComboBox{
        padding: 15px 0 0 0;
    }
    .InputComboBox .dropdown-menu{
        font-size: 1rem;
    }
}
.head-title{
    position: relative;
    margin:0;
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--primary-color);
    background-color: transparent;
    height: 70px;
    line-height: 70px;
    padding-left: 20px;
}
@media (max-width: 767.98px) {
    .head-title{
        font-size: 1.1rem;
        font-weight: 700;
        height: 50px;
        line-height: 50px;
    }
}

.selectaImg{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 20px 20px;
    background-color: #FFFFFF;
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selectaImg.lighter-bg{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 20px 20px;
    background-color: #FAFAFA !important;
    border: none;
    box-shadow: 0 3px 5px rgba(0,0,0,0) !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.selectaImg .btn-iselecta{
    /* position: relative; */
    display: inline-block;
    width: 95px;
    height: 95px;
    padding: 0;
    border: 0.5px solid #B2B2B2;
    box-sizing: border-box;
    background-color: #FFFFFF;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.selectaImg .box-other{
    display: block;
    background-color: #FAFAFA;
    margin: 20px 0;
    padding: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selectaImg.lighter-bg .box-other{
    display: block;
    background-color: #FAFAFA;
    margin: 10px 0 0 0;
    padding: 10px 20px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selectaImg .s-form{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 700px;
    border-radius: 5px;
    box-sizing: border-box;
}
.selectaImg.lighter-bg .s-form{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 700px;
    border-radius: 5px;
    box-sizing: border-box;
}
.selectaImg .other-value-i{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    background: #FFFFFF;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 150px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    text-align: center;
}
.selectaImg.lighter-bg .other-value{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    background: #FFFFFF;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 150px;
    height: 50px;
    line-height: 30px;
    box-sizing: border-box;
    text-align: center;
}
.selectaImg .selected{
    border: 5px solid var(--secondary-color) !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.selectaImg .statement{
    margin: 0 0 20px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.inline-block{
    display: inline-block;
}

@media (max-width: 767.98px) {
    .selectaImg .btn-iselecta{
        /* display: block; */
        width: 95px;
        height: 95px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        border: 0.5px solid #B2B2B2;
        box-sizing: border-box;
        background-color: #FFFFFF;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .selectaImg .s-form{
        width: 100%;
    }
}

@media (max-width: 575.98px) {
    .selectaImg .s-form{
        width: 90%;
    }
    .selectaImg.lighter-bg .s-form{
        width: 100%;
    }
}


.selectaImageItem{
    position: relative;
    display: inline-block;
}
.selectaImageItem button{
    position: relative;
    width: 95px;
    height: 95px;
    margin-right: 20px;
    padding: 0;
    border: none;
    box-sizing: border-box;
    background-color: #FFFFFF;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selectaImageItem button::after{
    position: absolute;
    top:0; left: 0; bottom: 0; right: 0;
    content:" ";
    border: 0.5px solid #B2B2B2;
    box-sizing: border-box;
    background-color: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.si-selected{
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.si-selected::after{
    position: absolute;
    top:0; left: 0; bottom: 0; right: 0;
    content:" ";
    border: 5px solid var(--secondary-color) !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 767.98px) {
    .selectaImageItem button{
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .selectaImageItem{
        /* display: flex;
        flex-wrap: wrap;
        margin: 5px auto !important;
        justify-content: center;*/
    } 
}




.ratebox{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 0 20px;
    background-color: #FFFFFF;
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow:  0 2px 3px rgba(0,0,0,0.1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.ratebox .statement{
    margin: 0 0 20px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
}
.ratebox > ul.listrate{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
ul.listrate > li{
    display: inline-block;
    margin: 0 100px 0 0;
    padding: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
ul.listrate > li > label.item-rate{
    display: block;
    margin: 0;
    padding: 0;
    width: 110px;
    height: 80px;
    text-decoration: none;
    text-align: center;
    color: #333333;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
label.item-rate > button{
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 38px;
    height: 38px;
    border:4px solid #B2B2B2;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
label.item-rate > span{
    display: block;
    margin: 10px 0 0 0;
    padding: 0;
    width: 100%;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
label.item-rate.r-selected > button{
    background-color: #0C73BF;
    border:0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
label.item-rate.r-selected > span{
    font-weight: bold;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.ratebox textarea{
    resize: none;
    outline: none;
}

.ratebox .description{
    position: relative;
    margin: 10px 0 20px 0;
    padding: 0;
    color: var(--dark-grey-color);
    font-size: 0.85rem;
}

@media (max-width: 575.98px) {
    .ratebox ul.listrate{
        display:flex;
        flex-direction: row !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    ul.listrate > li{
        display: inline-block;
        margin: 5px;
        padding: 0;
    }
    ul.listrate > li > label.item-rate{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
}
@media (max-width: 991.98px) {
    ul.listrate > li{
        display: inline-block;
        margin: 0 30px 0 0;
        padding: 0;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
}

.r-selected #verygood{
    fill: #0C73BF;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.r-selected #good{
    fill: #0C73BF;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.r-selected #bad{
    fill: #0C73BF;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.welcome{
    position: relative;
    background-color: var(--primary-color);
    min-height: 100vh;
    padding-bottom: 50px;
    background-image: url(/static/media/network.20258aaf.svg), url(/static/media/bytes.4ddcd847.svg), url(/static/media/mboa-filigranne.2084bdf5.svg);
    background-repeat: no-repeat;
    background-position:
    top 5px left 5px,
    top 5px right -5px,
    bottom 60px right -340px;
    background-size: 230px, 260px, 1100px;
}
.titled{
    font-weight: 400;
}
.bluely{
    color: var(--secondary-color) !important;
}
.bold{
    font-weight: 800 !important;
    color: var(--secondary-color);
}
.font-size-18{
    font-size: 18px;
}
.font-size-17{
    font-size: 17px;
}
.font-size-16{
    font-size: 16px;
}
.font-size-15{
    font-size: 15px;
}
.font-size-14{
    font-size: 14px;
}
.font-size-13{
    font-size: 13px;
}
.font-size-12{
    font-size: 12px;
}
.font-size-11{
    font-size: 11px;
}
.font-size-10{
    font-size: 10px;
}
.big-bold-white{
    font-weight: bold !important;
    color: var(--white-color);
    font-size: 3rem;
}
@media (max-width: 575.98px) {
    .welcome{
        min-height: 100vh;
        background-image: url(/static/media/network.20258aaf.svg), url(/static/media/mboa-filigranne.2084bdf5.svg);
        background-position: top 5px right 5px, bottom 0px right -500px;
        background-size: 200px, 1000px;
    }
    
}

.header{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    margin: 0; padding: 0;
    background-color: transparent;
    width: 100%;
}
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo img{
    position: relative;
    width: auto;
    height: 30px;
}
.title-page{
    margin-top: 25px;
}
.logo h2{
    position: relative;
    padding: 0;
    color: var(--white-color);
    font-weight: bold;
    font-size: 1.5em;
    text-transform: capitalize;
}
.main-title{
    margin: 0;
    padding: 0;
    color: var(--white-color);
    font-weight: 300;
    font-size: 1.5em;
}
@media (max-width: 575.98px) {
    .header{
        height: 100px;
    }
    .title-page h3{
        font-weight: 500;
        font-size: 1.3em;
    }  
}

footer.footer{
    position: absolute;
    display: block;
    bottom: 0; 
    left: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #FFFFFF;
    box-shadow: 0 -3px 5px rgba(0,0,0,.05);
    box-sizing: border;
}
.logo2 img{
    position: relative;
    width: auto;
    height: 35px;
}
.website{
    z-index: 999;
    position: relative;
    display: block;
    float: right;
}
.website img{
    position: relative;
    margin-right: 10px;
    width: 20px;
    height: auto;
}
.website a{
    text-decoration: none;
    font-weight: lighter;
    color: #333333;
}
.welcome{
    color: var(--white-color);
}
.welcome .content.container{
    position: relative;
}
.w-pmessage{
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 1rem;
    font-weight: 500;
}
.w-pmessage > p {
    margin: 4px;
}
span.ico-tech{
    position: relative;
    display: block;
    margin: 0 20px 0 0; padding: 0;
    width: 200px;
    height: 200px;
}
.time-defined{
    font-size: 2.5rem;
}

@media (max-width: 575.98px) {
    
    .w-pmessage{
        display: flex;
        flex-direction: column;
        position: relative;
        font-size: 1.1rem;
        align-items: center;
    }
    span.ico-tech{
        position: relative;
        display: block;
        margin: 0; padding: 0;
        width: 180px;
        height: 180px;
        /* background-image: url(../../../assets/svg/digitalComputer.svg);
        background-repeat: no-repeat;
        background-position: top;
        background-size: 180px; */
    }
    .time-defined{
        font-size: 2rem;
    }
    .timer-msg + p {
        font-size: 0.9rem;
    }
    .w-600{
        width: inherit;
    }
}

@media (max-width: 768px) {
    
    .w-pmessage{
        display: flex;
        flex-direction: column;
        position: relative;
        font-size: 1rem;
        align-items: center;
    }
    .ico-on-hover{
        margin-top: 50px;
    }
    .timer-msg{
        font-size: 1.1rem;
    }
    .timer-msg .bold{
        font-size: 1.3rem;
    }
}

@-webkit-keyframes rotate-niddle {
    from{
        transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
        -moz-transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -o-transform: rotateZ(0deg);
}
    to{
        transform: rotateZ(360deg);
        -webkit-transform: rotateZ(360deg);
        -moz-transform: rotateZ(360deg);
        -ms-transform: rotateZ(360deg);
        -o-transform: rotateZ(360deg);
}
}

@keyframes rotate-niddle {
    from{
        transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
        -moz-transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -o-transform: rotateZ(0deg);
}
    to{
        transform: rotateZ(360deg);
        -webkit-transform: rotateZ(360deg);
        -moz-transform: rotateZ(360deg);
        -ms-transform: rotateZ(360deg);
        -o-transform: rotateZ(360deg);
}
}

#niddle{
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    transform-box: fill-box;
    animation: rotate-niddle 10s steps(60, end) 0s infinite;
    -webkit-animation: rotate-niddle 10s steps(60, end) 0s infinite;
}

@media (max-width: 575.98px) {
    .chronometter{
        width: 100px;
        height: auto;
    }
}
.welcome .content.container .end{
    position: relative;
    background-image: url(/static/media/network-darker.2e62b4ff.svg);
    background-repeat: no-repeat;
    background-position: top 0px right 5px;
    background-size: 300px;
    min-height: 250px;
    background-color: transparent;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content-message{
    display: flex;
    position: relative;
    font-size: 1.5rem;
    font-weight: lighter;
    width: 610px;
}
span.ico-smiley{
    position: relative;
    display: block;
    margin: 0; padding: 0;
    width: 100px;
    height: 100px;
    background-image: url(/static/media/smiley-end.dca41d82.svg);
    background-repeat: no-repeat;
    background-position: center left 10px;
    background-size: 50px;
}
.content-message div{
    display: flex;
    padding: 0 0 0 25px;
    margin-bottom: 0 !important;
    align-items: center;
}
@media (max-width: 768px) {
    .content-message{
        flex-direction: column;
        font-size: 1.3rem;
        justify-content: center;
        align-items: center;
    }
    
}
@media (max-width: 575.98px) {
    /* .font-size-17{
        font-size: 14px;
    } */
    .big-bold-white{
        font-size: 25px;
    }
}

.HeaderStep{
    position: relative;
    display: block;
    height: 175px;
    margin: 0; padding: 0;
    background-color: var(--primary-color);
    background-image: url(/static/media/network.20258aaf.svg), url(/static/media/bytes.4ddcd847.svg);
    background-repeat: no-repeat;
    background-position:
    top 5px left 5px,
    top 0px right -15px;
    background-size: 210px, 260px;
    overflow: hidden;
    box-shadow: 0 2px 3px rgba(0,0,0,.16);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.HeaderStep.offset{
    z-index: 999;
    position: fixed;
    display: block;
    top:0; left:0; right:0;
    height: auto;
    margin: 0; padding: 0;
    background-color: var(--primary-color);
    overflow: hidden;
    box-shadow: 0 2px 3px rgba(0,0,0,.16);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.HeaderStep .main-title{
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: bold;
}
.HeaderStep.offset .main-title{
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
}
.HeaderStep .logo{
    position: relative;
    margin-top: 25px; 
    margin-bottom: 15px;
    padding: 0;
}
.HeaderStep .logo img{
    position: relative;
    width: auto;
    height: 30px;
}
.HeaderStep .title-page{
    display: flex;
    margin-top: 25px;
    align-items: baseline;
    justify-content: space-between;
}
.HeaderStep .title-page h3{
    position: relative;
    padding: 0;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 1.5em;
}

.HeaderStep .step-box{
    /* position: absolute; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 75px;
    margin: 0; padding: 0;
    background-color: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0,0,0,.16);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


.HeaderStep .step-box .step-item{
    position: relative;
    display: inline-block;
    margin: 5px 0 0 0;
    height: inherit;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.HeaderStep .step-box .step-item button.btn-step{
    position: relative;
    display: block;
    margin: 5px auto 2px auto;
    width: 40px;
    height: 40px;
    border: 5px solid var(--grey-color);
    border-radius: 50%;
    box-sizing: border-box;
    background-color: var(--white-color);
    color: var(--grey-color);
    font-weight: bold;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.HeaderStep .step-box .step-item .step-label{
    position: relative;
    font-size: 0.8rem;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.HeaderStep .step-box .step-item.selected button.btn-step{
    border: 5px solid var(--secondary-color);
    box-sizing: border-box;
    color: var(--secondary-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.HeaderStep .step-box .step-item.selected .step-label{
    font-weight: bold;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.HeaderStep .step-box .step-item.complete button.btn-step{
    border: 0;
    background-color: var(--secondary-color);
    color: var(--white-color);
    font-weight: bold;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.HeaderStep .step-box .step-item.complete .step-label{
    font-weight: normal;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 767.98px) {
    .HeaderStep{
        height: 110px;
        margin: 0; padding: 0;
        background-position:
        top 0px left 0px,
        top 0px right -5px;
        background-size: 105px, 130px;
    }
    .HeaderStep.offset .main-title{
        height: 50px;
        line-height: 50px;
    }
    .HeaderStep .main-title{
        height: 50px;
        line-height: 50px;
    }
    .HeaderStep .step-box{
        height: 60px;
    }
    .HeaderStep .step-box .step-item .step-label{
        display: none;
    }
    .HeaderStep .step-box .step-item.selected button.btn-step{
        border: 8px solid var(--secondary-color);
        box-sizing: border-box !important;
        width: 40px !important;
        height: 40px !important;
        border-radius: 50%  !important;
        -webkit-border-radius: 50%  !important;
        -moz-border-radius: 50%  !important;
        -ms-border-radius: 50%  !important;
        -o-border-radius: 50%  !important;
}
}
