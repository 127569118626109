@import url(../../App-vars.css);

.selecta{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 20px 20px;
    background-color: var(--white-color);
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta.lighter-bg{
    display: block;
    margin: 0 0 20px 0;
    padding: 20px 50px;
    background-color: var(--light-grey-color) !important;
    border: none;
    box-shadow: 0 3px 5px rgba(0,0,0,0) !important;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn-selecta{
    position: relative;
    display: inline-block;
    height: 50px;
    margin-right: 20px;
    padding: 0 20px;
    border: 0.5px solid var(--grey-color);
    box-sizing: border-box;
    background-color: var(--white-color);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta .btn-selecta.img{
    position: relative;
    display: inline-block;
    height: 95px;
    line-height: 95px;
    width: 95px;
    padding: 0;
    text-align: center;
    border: 0.5px solid var(--grey-color);
    box-sizing: border-box;
    background-color: var(--white-color);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta .box-other{
    display: block;
    background-color: var(--light-grey-color);
    margin: 20px 0;
    padding: 20px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta.lighter-bg .box-other{
    display: block;
    background-color: var(--light-grey-color);
    margin: 20px 0 0 0;
    padding: 20px 20px 0 0;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta .s-form{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 50px;
    width: 700px;
    border-radius: 5px;
    box-sizing: border-box;
}
.selecta.lighter-bg .s-form{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 700px;
    border-radius: 5px;
    box-sizing: border-box;
}
.other-value{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    background: var(--white-color);
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 150px;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    text-align: center;
}
.lighter-bg .other-value{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    background: var(--white-color);
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 150px;
    height: 50px;
    line-height: 30px;
    box-sizing: border-box;
    text-align: center;
}
.selecta .selected{
    border: 5px solid var(--secondary-color) !important;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.selecta .statement{
    margin: 0 0 10px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
}
.inline-block{
    display: inline-block;
}
.selecta textarea {
    resize: none;
}