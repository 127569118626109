@import url('https://fonts.googleapis.com/css?family=Exo:200,300,400,500,700&display=swap');
@import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');
html,body {
  margin: 0;
  font-family:  "Exo",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  font-size: 0.9em;
  -ms-scroll-behavior: smooth;
  -moz-scroll-behavior: smooth;
  -o-scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input, button, select{
  outline: none !important;
}
