@import url(./App-vars.css);

.App {
  position: relative;
  font-family:  "Exo","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
  margin: 0; padding: 0;
  background-color: var(--light-grey-color);
  min-height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.mt-10{
  margin-top: 10px;
}
.mt-15{
  margin-top: 15px !important;
}
.mt-20{
  margin-top: 20px;
}
.mt-30{
  margin-top: 30px;
}
.mt-40{
  margin-top: 40px;
}
.mt-50{
  margin-top: 50px;
}
.mt-100{
  margin-top: 100px;
}
.mb-100{
  margin-bottom: 100px;
}
.ml-20{
  margin-left: 20px;
}
.w-550{
  width: 550px;
}
.w-600{
  width: 600px;
}
.mb-20{
  margin-bottom: 20px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.p-start{opacity: 0}
.actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.container{
  padding: 0 75px !important;
}
.pt-100{
  padding-top: 100px !important;
}
.pb-50{
  padding-bottom: 50px !important;
}
.none{
  display: none
}
.block{
  display: block
}
.light-white-color {
  color: var(--light-white-color);
}
input[type="number"],
input[type="text"],
select:focus,
textarea {
  font-size: 16px;
}
@media (max-width: 767.98px) {
  .container{
    padding: 0 15px !important;
    box-sizing: border-box;
  }
  .actions-wrapper {
    flex-direction: column;
    justify-content: center;
  }
}

