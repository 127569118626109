@import url(../../App-vars.css);

.head-title{
    position: relative;
    margin:0;
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--primary-color);
    background-color: transparent;
    height: 70px;
    line-height: 70px;
    padding-left: 20px;
}
@media (max-width: 767.98px) {
    .head-title{
        font-size: 1.1rem;
        font-weight: 700;
        height: 50px;
        line-height: 50px;
    }
}