@import url(../../../App-vars.css);

.selectaItem{
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}
.selectaItem button{
    position: relative;
    min-height: 40px;
    margin-right: 10px;
    padding: 0 10px;
    border: 0.5px solid #B2B2B2;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.selectaItem .s-selected{
    border: 5px solid var(--secondary-color) !important;
    padding: 0 5.5px !important;
    box-sizing: border-box !important;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
div.popover{
    /* background-color: #CBE9FF;
     border-radius: 0; */
    font-family: "Exo", Arial, Helvetica, sans-serif
}
.arrow::after,.arrow::before{
    border-color: #CBE9FF; 
}

@media (max-width: 575.98px) {
    .selectaItem{
        position: relative;
        display: block;
    }
    .selectaItem button{
        position: relative;
        margin: 0 0 5px 0;
        padding: 0 10px;
        border: 0.5px solid #B2B2B2;
        width: 100%;
        border-radius: 5px;
        font-size: 0.9rem;
        box-sizing: border-box;
        background-color: #FFFFFF;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
}

@media (max-width: 1024px) {
    .selectaItem{
        margin-bottom: 10px;
    }
}
