@import url(../../App-vars.css);
.inputbox{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 0 0 5px;
    background-color: #FFFFFF;
    border: 0.25px solid rgba(112,112,112,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
}
.inputbox.lighter-bg{
    display: block;
    margin: 0 0 10px 0;
    padding: 15px 20px 0 20px;
    background-color: #FAFAFA !important;
    border: none;
    box-shadow: 0 3px 5px rgba(0,0,0,0) !important;
}
.inputbox .statement{
    margin: 0 0 5px 0;
    font-weight: bold;
    color: var(--dark-grey-color);
}
.inputbox .txt-input{
    position: relative;
    border: thin solid rgba(112,112,112,0.3);
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
}